import { Home, ThemeEditor, Login, SignUp } from "pages";
import MasterLayout from "./master"
import UserLayout from "./user"
import { BrowserRouter, Routes, Route, useNavigate, useRoutes } from "react-router-dom";
import { MessageProvider } from "hooks/message"
import { AuthenticationProvider, useAuthentication } from "hooks/authentication"
import { Button, ConfigProvider, Space } from 'antd';
import "./app.css"
import { useEffect, useState } from "react";



function App() {

    const { login, auth } = useAuthentication();
    const [isMaster, setIsMaster] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            var master = auth.roles.includes("admin");
            setIsMaster(master);
        }

    }, [auth])

    useEffect(() => {
        //navigate("/login")
    }, [])
    // const router = useRoutes();

    return (

        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#005ac1',
                    colorLink : '#005ac1',
                
                },
            }}
        >
            <MessageProvider>
                {auth &&
                    <>
                        {isMaster ?
                            <MasterLayout></MasterLayout>
                            :
                            <UserLayout></UserLayout>
                        }
                    </>

                }
                {!auth &&
                    <Routes>
                        <Route path="/signup" element={<SignUp></SignUp>}>
                        </Route>
                        <Route path="*" element={<Login></Login>}>
                        </Route>
                    </Routes>
                }


            </MessageProvider>

        </ConfigProvider>

    );
}



const Root = () => {
    return (
        <BrowserRouter>
            <AuthenticationProvider>
                <App></App>
            </AuthenticationProvider>
        </BrowserRouter>
    )

}

export default Root;
