import React, { useState, memo, useEffect, useRef } from 'react';
import { Button, Modal, Tabs, Image, Space, Upload } from 'antd';
import { SelectButton } from "components"
import { FullscreenOutlined, UploadOutlined } from "@ant-design/icons"
import { apiPath } from "config"


const ImageSelect = (props) => {
    const { selected, onClick, src } = props;
    const [visible, setVisible] = useState(false);
    return (
        <SelectButton selected={selected} onClick={e => onClick(src)}>


            <div style={{ position: "relative" }}   >

                <img src={`${apiPath}image/resize?url=${src}&w=150`} style={{ borderRadius: 4, width: 100, height: 100, objectFit: "cover" }}></img>
                {/* <div style={{ position: "absolute", top: 0, right: 0 }}>
                    <Button type='link' onClick={e => setVisible(true)}><FullscreenOutlined /></Button>
                </div>

                <Image
                    {...props}

                    style={{ display: "none" }}
                    preview={{
                        visible,
                        src: src,
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                    }}
                /> */}
            </div>
        </SelectButton>
    )

}

export default memo(ImageSelect);