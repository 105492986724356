import React, { useState, memo, useEffect } from 'react';
import {ColorPicker } from 'antd';

const ColorPicker2 = (props) => {
    return (
        <ColorPicker {...props}
            format={"hex"}
            presets={[
                {
                    label: 'Trending',
                    colors: [
                        "#ffffff",
                        "#fec5bb",
                        "#fcd5ce", "#fae1dd", "#f8edeb", "#e8e8e4", "#d8e2dc", "#ece4db", "#ffe5d9", "#ffd7ba", "#fec89a"

                        , "#03045e",
                        "#023e8a",
                        "#0077b6",
                        "#0096c7",
                        "#00b4d8",
                        "#48cae4",
                        "#90e0ef",
                        "#ade8f4",
                        "#caf0f8",
                        "#000000",

                    ]
                    ,
                },

            ]}
        ></ColorPicker>
    )

}
export default memo(ColorPicker2)