import React, { Component, useState, useEffect, createContext, useContext } from 'react';
import { message, Modal } from 'antd';

const MessageContext = createContext();

const MessageProvider = ({ children }) => {

    const [messageApi, contextHolder] = message.useMessage();
    const [modal, contextHolder2] = Modal.useModal();
    const showSuccess = (content) => {
        messageApi.open({
            type: 'success',
            content: content,
        });
    }

    const showError = (content) => {
        messageApi.open({
            type: 'error',
            content: content,
        });
    }


    const showConfirm = async (title="Delete?", content= "Are you sure you want to delete?") => {
        return await modal.confirm({
            title: title,
            content: content,
        });
    }








    return (
        <MessageContext.Provider
            value={{ showSuccess, showError, showConfirm }}
        >
            {contextHolder}
            {contextHolder2}
            {children}

        </MessageContext.Provider>
    );

}

const useMessage = () => {
    const context = useContext(MessageContext);
    return context;
}


export { MessageProvider, useMessage }
