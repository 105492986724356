import { memo, useEffect, useState } from "react"
import { Button, Space, Table, Image, Card, Select } from 'antd';
import api from "api"
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useMessage } from "hooks/message"
const Images = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [imageType, setImageType] = useState("background");
    const message = useMessage();
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        var { data } = await api.get("/api/master/images/list", { params: { type: imageType } });
        setData(data.data);
    }

    useEffect(() => {
        setData([]);
        loadData();
    }, [imageType])


    const goToEdit = (id) => {
        navigate(`/theme/${id}`)
    }

    const deleteItem = async (id) => {
        var confirm = await message.showConfirm();
        if (confirm) {
            await api.post("/api/master/images/delete", { id: id });
            loadData();
        }
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Space size="middle">

                <Image
                    width={75}
                    src={record.url}
                />
            </Space>,
        },

        {
            title: <Button onClick={e => navigate(`/master/images/add/${imageType}`)}>Thêm mới</Button>,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={e => goToEdit(record.id)}>Edit</Button>
                    <Button type="link" onClick={e => deleteItem(record.id)}>Delete</Button>
             
                </Space>
            ),
        },
    ];



    return (
        <div>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Card>
                    <Select
                        value={imageType}
                        style={{ width: 120 }}
                        onChange={e => setImageType(e)}
                        options={[
                            { value: 'background', label: 'Background' },
                            { value: 'icon', label: 'Icon' },

                        ]}
                    />
                </Card>
                <Card>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Space>


        </div>
    )
}

export default memo(Images)
