
const pageTitle = {
    fontSize: "18px",
    fontWeight: 600,

}
const buttonTitle = {
    fontSize: "16px",
    fontWeight: 600,

}

const buttonIcon = {
    fontSize: "16px",
    fontWeight: 600,
}




const button = {
    borderRadius: "30px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgb(255 255 255 / 30%)",
    width: "100%",
    background: "linear-gradient(263.81deg, rgba(255, 255, 255, 0.4) 18.8%, rgba(255, 255, 255, 0) 73.34%), rgba(255, 255, 255, 0.25)"
}

const pageSocical = {
    fontSize: "28px",
    marginLeft: 12,
    marginRight: 12,
}


const defaultTheme = {
    pageTitle: pageTitle,
    pageSocical: pageSocical,
    button: button,
    buttonIcon: buttonIcon,
    buttonTitle: buttonTitle,
    background: {
        background: `url("https://storage.googleapis.com/cubebio/default/bg.jpg")`,
        backgroundSize: "cover"
    },
  



}




export default defaultTheme;

