import React, { useState, memo, useEffect, useRef } from 'react';
import { Button, Modal, Tabs, Image, Space, Upload, theme, Flex } from 'antd';
import { SelectButton, ImageSelect } from "components"
import { FullscreenOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import ImgCrop from 'antd-img-crop';
import api from "api"
const { useToken } = theme;
const ImagePicker = ({ open, afterClose, onChange, value, file_type, crop, hiddenGallery }) => {
    const { token } = useToken();
    const [isModalOpen, setIsModalOpen] = useState(open);
    const [selected, setSeleted] = useState(value);

    useEffect(() => {
        setIsModalOpen(open);
    }, [open])

    useEffect(() => {
        if (value != selected) {
            setSeleted(value)
        }
    }, [value]);





    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        onChange(selected);

        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };

    const remove = () => {
        onChange(null);
    }
    console.log("render image picker")

    return (
        <>
            <div style={{ width: 150 }}>

                <div style={{ height: 150, width: 150, position: "relative", borderRadius: token.borderRadius, borderWidth: 1, borderColor: token.colorBorder, padding: token.paddingXS, borderStyle: "dashed", marginBottom: token.paddingXS }}>
                    {value ?
                        <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={value}></img>
                        :
                        <Flex style={{ height: "100%", width: "100%" }} justify='center' align='center'>

                            No Image
                        </Flex>
                    }
                    <div style={{ position: "absolute", top: 8, right: 8 }}>
                        <Button onClick={remove} type='link'> <DeleteOutlined /></Button>
                    </div>

                </div>
                <Flex style={{ width: "100%" }} justify='center' align='center'>
                    <Button onClick={showModal}>Chọn hình</Button>

                </Flex>


                <Modal title="Images" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} afterClose={afterClose} >
                    {hiddenGallery ?
                        <UploadTab onSelect={url => setSeleted(url)} value={selected} file_type={file_type} crop={crop}> </UploadTab>
                        :
                        <Tabs
                            tabPosition={"top"}
                            items={
                                [
                                    {
                                        label: 'Gallery',
                                        key: 'upGallery',
                                        children: <Gallery onSelect={e => setSeleted(e)} value={selected} file_type={file_type}></Gallery>,
                                    },
                                    {
                                        label: 'Upload',
                                        key: 'uploadTab',
                                        children: <UploadTab onSelect={url => setSeleted(url)} value={selected} file_type={file_type} crop={crop}> </UploadTab>,
                                    },

                                ]
                            }
                        />
                    }

                </Modal>
            </div>
        </>
    );
};

const UploadTab = memo(({ onSelect, value, file_type, crop }) => {
    console.log("value", value)
    const uploadInput = useRef("file");
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        console.log("value chaneg", value)
    }, [value])

    useEffect(() => {

        loadList();
    }, []);
    const loadList = async () => {
        var res = await api.get("api/user/file/list", { params: { file_type: file_type } });
        setFileList(res.data.data)
    }

    const uploadFile = async (file) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_type", file_type);
        var res = await api.post("api/user/file/upload", formData);
        onSelect(res.data.data.url);
        loadList();
        setLoading(false);

    }
    const onChange = (event) => {
        var { files } = event.target;
        if (files.length == 0) {
            return;
        }
        uploadFile(files[0]);

    }

    const beforeUpload = async (file) => {
        await uploadFile(file);
        return false;
    }

    const uploadButton = <Upload beforeUpload={beforeUpload} showUploadList={false}>
        <Button loading={loading} icon={<UploadOutlined />}>{loading ? "Uploading" : "Click to Upload"}</Button>
    </Upload>


    return (
        <div>
            <div>
                {crop ?
                    <ImgCrop {...crop}>
                        {uploadButton}
                    </ImgCrop>
                    :
                    <>
                        {uploadButton}
                    </>


                }


                {/* <input type="file" onChange={onChange} ref={uploadInput} style={{ display: "none" }} />

                <Button loading={loading} icon={<UploadOutlined />} onClick={e => { uploadInput.current.click() }}>{loading ? "Uploading" : "Click to Upload"}</Button> */}

            </div>
            Mới Upload
            <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>
                {fileList.map(file => {
                    return (
                        <ImageSelect

                            src={file.url}
                            selected={file.url == value}
                            onClick={e => onSelect(file.url)}
                        />
                    )
                })}
            </div>
        </div>
    )
})

const Gallery = memo(({ onSelect, value, file_type }) => {


    const [data, setData] = useState([]);
    useEffect(() => {
        loadData();
    }, [])
    const loadData = async () => {
        var { data } = await api.get("api/user/file/systemimage", { params: { file_type: file_type } });
        setData(data.data);
    }


    return (

        <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>
            {data.map(i => {
                return (
                    <ImageSelect

                        src={i.url}
                        selected={i.url == value}
                        onClick={e => onSelect(i.url)}
                    />
                )
            })}
        </div>

    )
})


export default memo(ImagePicker);