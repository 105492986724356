import React from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, UnorderedListOutlined, FileImageOutlined,ChromeOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Dropdown, Space } from 'antd';

import { ThemeEditor, ThemeList, ImageManagement, ImageAdd } from "pages/master";
import DesignPage from 'pages/user/design';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { AuthenticationProvider, useAuthentication } from "hooks/authentication"
const { Header, Content, Footer, Sider } = Layout;
const App = () => {
    const { login, auth, logout } = useAuthentication();
    const {
        token: { colorBgContainer,colorPrimary },
    } = theme.useToken();
    const navigate = useNavigate();

    const items = [
        {
            key: 'logout',
            label: (
                <a onClick={logout}>
                    Logout
                </a>
            ),
        },

    ];
    return (

        <Layout style={{ minHeight: "100vh", padding: 0 }}>
            <Sider
                breakpoint="lg"
                collapsedWidth="50"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                collapsed={true}
                style={{ position: "fixed", height: "100vh", top: 50, backgroundColor: colorPrimary }}
            >
                <div className="demo-logo-vertical" />
                <Menu

                    mode="inline"
                    defaultSelectedKeys={['4']}
                    items={

                        [

                            {
                                key: "lk",
                                icon: <UnorderedListOutlined />,
                                label: `Themes`,
                                onClick: e => { navigate("/theme/list") }
                            },
                            {
                                key: "images",
                                icon: <FileImageOutlined /> ,
                                label: `Images`,
                                onClick: e => { navigate("/master/images/list") }
                            },
                            {
                                key: "design",
                                icon: <ChromeOutlined />,
                                label: `Design`,
                                onClick: e => { navigate("/design/default") }
                            },

                        ]

                    }
                />
            </Sider>
            <Layout>
                <Header style={{
                    padding: 0, background: colorBgContainer, position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    borderBottomColor: "#f9f9f9",
                    borderBottomWidth: 1,
                    backgroundColor: colorPrimary
                }} >
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", flexDirection: "row" }}>
                        <div style={{ marginRight: 16 }}>
                            <Dropdown menu={{ items }}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {auth.full_name}
                                    </Space>
                                </a>
                            </Dropdown>

                        </div>
                    </div>
                </Header>
                <Content style={{ padding: 8, backgroundColor: "white", marginLeft: 50 }}>
                    <div style={{ minHeight: 360 }}>
                        <Routes>
                            <Route path="theme/list" element={<ThemeList />} />
                            <Route path="theme/:id" element={<ThemeEditor />} />
                            <Route path="design/:id" element={<DesignPage />} />
                            <Route path="master/images/list" element={<ImageManagement />} />
                            <Route path="master/images/add/:type" element={<ImageAdd />} />
                            <Route path="/" element={<div>Home</div>}>
                            </Route>
                        </Routes>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Cubetech 2023</Footer>
            </Layout>
        </Layout>

    );
};

export default App;