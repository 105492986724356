import React, { useState } from 'react';
import { Button, Flex, Modal, Space } from 'antd';
import QRCode from "react-qr-code";

const QrModal = ({ url, title, description }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="link" onClick={showModal} >
                {title}
            </Button>
            <Modal title={null} open={isModalOpen} onCancel={handleCancel}


                footer={[
                    <Button type="primary" onClick={handleCancel}>
                        Ok
                    </Button>,

                ]}

            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                        {description}
                    </div>

                    <QRCode value={url} />
                    <div style={{ marginTop: 16 }}>
                        <a href={url} target='_blank'>Open new tab</a>
                    </div>

                </div>

            </Modal>
        </>
    );
};

export default QrModal;
