import React, { useState, memo, useEffect, useRef } from 'react';
import { Button, Modal, Tabs, Image, Space, theme, Flex, Form, Input, Collapse } from 'antd';
import { SelectButton } from "components"
import { FullscreenOutlined, UploadOutlined, DragOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactSortable } from "react-sortablejs";
import FormItem from 'antd/es/form/FormItem';
import _ from "lodash"
import { toArray, newID } from "common"
import { ImagePicker } from 'components/picker';
import { useMessage } from 'hooks/message';
const { useToken } = theme;



const SocicalLinks = ({ value, onChange }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showAddHeaderModal, setShowAddHeaderModal] = useState(false);
    const [showAddPhoneBookModal, setShowAddPhoneBookModal] = useState(false);

    const [socicals, setSocicals] = useState(toArray(value));
    const [editItem, setEditItem] = useState(null);
    const message = useMessage();

    useEffect(() => {

        var maplinks = socicals.map(x => ({ id: x.id, url: x.url, title: x.title, type: x.type, icon: x.icon }));
        var valueMap = toArray(value).map(x => ({ id: x.id, url: x.url, title: x.title, type: x.type, icon: x.icon }));
        var equa = _.isEqual(valueMap, maplinks);
        if (!equa) {
            setSocicals(maplinks)
        }
    }, [value])


    const onAdd = (link) => {
        var newLinks = [...socicals];
        var index = newLinks.findIndex(x => x.id == link.id);
        if (index >= 0) {
            newLinks[index] = link;
        } else {
            newLinks.push(link);
        }

        setSocicals(newLinks);
        linksChange(newLinks)
    }
    const onRemove = async (link) => {
        var confirm = await message.showConfirm();
        if (!confirm) {
            return;
        }
        var newLinks = [...socicals];
        newLinks = newLinks.filter(x => x.id != link.id);
        setSocicals(newLinks);
        linksChange(newLinks);
    }


    const sortChange = (links) => {
        setSocicals(links);
        linksChange(links);
    }


    const linksChange = (links) => {
        // var maplinks = links.map(x => ({ id: x.id, url: x.url, title: x.title, type: x.type, icon: x.icon }));
        // var valueMap = toArray(value).map(x => ({ id: x.id, url: x.url, title: x.title, type: x.type, icon: x.icon }));
        // var equa = _.isEqual(valueMap, maplinks);
        // if (!equa) {
        //     onChange(maplinks);

        // }

        onChange(links);

    }


    const onEditLink = (link) => {
        setEditItem(link);
        setShowAddModal(true);
    }

    const onEditVcard = (link) => {
        setEditItem(link);
        setShowAddPhoneBookModal(true);
    }


    const onEditHeader = (link) => {
        setEditItem(link);
        setShowAddHeaderModal(true);
    }



    const btnAddLinkClick = () => {
        setEditItem(null);
        setShowAddModal(true);
    }

    const btnAddPhoneBookClick = () => {
        setEditItem(null);
        setShowAddPhoneBookModal(true);
    }


    const btnAddHeaderClick = () => {
        setEditItem(null);
        setShowAddHeaderModal(true);
    }




    return (
        <div>
            <Space direction='vertical' style={{ width: "100%" }}>

                <div>
                    <ReactSortable list={socicals} setList={sortChange} swapThreshold={1}
                        animation={0}
                        group="pagelink"
                        handle='.handle'
                    >
                        {socicals.map(link => {
                            if (link.type == "link")
                                return (
                                    <Link key={`link_socical_${link.id}`} link={link} onRemove={onRemove} onEdit={onEditLink}> </Link>
                                )
                            if (link.type == "addphonebook")
                                return (
                                    <Link key={`link_socical_${link.id}`} link={link} onRemove={onRemove} onEdit={onEditVcard}> </Link>
                                )
                            if (link.type == "header")
                                return (
                                    <Header key={`link_socical_header_${link.id}`} link={link} onRemove={onRemove} onEdit={onEditHeader}> </Header>
                                )
                        })}
                    </ReactSortable>

                </div>
                <div>
                    <Flex justify='flex-end'>
                        <Space>
                            <Button onClick={btnAddLinkClick} type='primary'>Add Link</Button>
                            <Button onClick={btnAddPhoneBookClick} type='primary'>Add Vcard</Button>
                            <Button onClick={btnAddHeaderClick} type='primary' >Add Header</Button>

                        </Space>
                    </Flex>


                </div>
            </Space>
            <ModalAdd open={showAddModal} afterColse={e => setShowAddModal(false)} onAdd={onAdd} item={editItem}></ModalAdd>
            <ModalAddHeader open={showAddHeaderModal} afterColse={e => setShowAddHeaderModal(false)} onAdd={onAdd} item={editItem} ></ModalAddHeader>
            <ModalAddVcard open={showAddPhoneBookModal} afterColse={e => setShowAddPhoneBookModal(false)} onAdd={onAdd} item={editItem}></ModalAddVcard>

        </div>
    )

}


const ModalAdd = memo(({ open, afterColse, onAdd, item }) => {

    const [modalOpen, setModalOpen] = useState(open);
    useEffect(() => {
        setModalOpen(open);
        // socicalForm.setFieldsValue({ url: "https://www.facebook.com/toan.vuvan.73", title: "New Link" })
    }, [open])


    useEffect(() => {
        if (item) {
            socicalForm.resetFields();
            socicalForm.setFieldsValue(item)
        } else {
            socicalForm.setFieldsValue({ id: null, url: "", title: "New Link" })
        }


    }, [item]);




    const close = () => {
        setModalOpen(false);
    }
    const [socicalForm] = Form.useForm();
    const onOk = () => {
        var data = socicalForm.getFieldsValue(true);
        var item = { ...data, type: "link" };
        if (!item.id) {
            item.id = newID()
        }
        onAdd(item)
        close();
    }
    return (
        <Modal title={"Link"}
            open={modalOpen} afterClose={afterColse} onCancel={close} onOk={onOk}

        >
            <Form
                form={socicalForm}
                name="control-socicalform"
                layout='vertical'
            >
                <FormItem name={"title"} label="Title">
                    <Input></Input>
                </FormItem>
                <FormItem name={"url"} label="Url">
                    <Input></Input>
                </FormItem>
                <FormItem name={"icon"} label="Icon">
                    <ImagePicker file_type="icon" crop={{ cropShape: "round", minZoom: 0.1, cropperProps: { cropSize: { width: 100, height: 100 } } }} />
                </FormItem>
            </Form>




        </Modal>
    )
})



const ModalAddVcard = memo(({ open, afterColse, onAdd, item }) => {

    const [modalOpen, setModalOpen] = useState(open);
    useEffect(() => {
        setModalOpen(open);
        // socicalForm.setFieldsValue({ url: "https://www.facebook.com/toan.vuvan.73", title: "New Link" })
    }, [open])


    useEffect(() => {
        if (item) {
            socicalForm.resetFields();
            socicalForm.setFieldsValue(item)
        } else {
            socicalForm.resetFields();
            socicalForm.setFieldsValue({ id: null, url: "", title: "Lưu số điện thoại", icon: "https://storage.googleapis.com/cubebio/default/bg/dea0ec8a-408e-44e9-8b04-388544520a63.png" })
        }


    }, [item]);




    const close = () => {
        setModalOpen(false);
    }
    const [socicalForm] = Form.useForm();
    const onOk = () => {
        var data = socicalForm.getFieldsValue(true);
        var item = { ...data, type: "addphonebook" };
        if (!item.id) {
            item.id = newID()
        }
        onAdd(item)
        close();
    }
    return (
        <Modal title={"Vcard"}
            open={modalOpen} afterClose={afterColse} onCancel={close} onOk={onOk}

        >
            <Form
                form={socicalForm}
                name="control-socicalform"
                layout='vertical'
            >
                <FormItem name={"title"} label="Title">
                    <Input></Input>
                </FormItem>
                <FormItem name={"name"} label="Your name">
                    <Input></Input>
                </FormItem>
                <FormItem name={"phone"} label="Phone">
                    <Input></Input>
                </FormItem>
                <FormItem name={"email"} label="Email">
                    <Input></Input>
                </FormItem>


                <FormItem name={"icon"} label="Avatar">
                    <ImagePicker file_type="icon" crop={{ cropShape: "round", minZoom: 0.1, cropperProps: { cropSize: { width: 100, height: 100 } } }} />
                </FormItem>

                <Collapse
                    collapsible="header"

                    items={[
                        {
                            key: 'infomore',
                            label: 'More infomation',
                            children: <div>
                                <FormItem name={"company"} label="Company">
                                    <Input></Input>
                                </FormItem>
                                <FormItem name={"job_title"} label="Your Job">
                                    <Input></Input>
                                </FormItem>
                                <FormItem name={"address"} label="Address">
                                    <Input></Input>
                                </FormItem>
                                <FormItem name={"website"} label="Website">
                                    <Input></Input>
                                </FormItem>
                            </div>,
                        },
                    ]}
                />
            </Form>




        </Modal>
    )
})



const ModalAddHeader = memo(({ open, afterColse, onAdd, item }) => {

    const [modalOpen, setModalOpen] = useState(open);
    useEffect(() => {
        setModalOpen(open);

    }, [open]);
    useEffect(() => {
        if (item) {
            socicalForm.setFieldsValue(item)
        } else {
            socicalForm.setFieldsValue({ id: null, title: "Header" })
        }

    }, [item]);




    const close = () => {
        setModalOpen(false);
    }
    const [socicalForm] = Form.useForm();
    const onOk = () => {
        var data = socicalForm.getFieldsValue(true);
        var item = { ...data, type: "header" };
        if (!item.id) {
            item.id = newID()
        }
        onAdd(item)
        close();
    }
    return (
        <Modal title={"Header"}
            open={modalOpen} afterClose={afterColse} onCancel={close} onOk={onOk}
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            )}
        >

            <Form
                form={socicalForm}
                name="control-socicalform"
                layout='vertical'
            >
                <FormItem name={"title"} label="Title">
                    <Input></Input>
                </FormItem>
            </Form>
        </Modal>
    )
})


const Link = memo(({ link, onRemove, onEdit }) => {
    const { token } = useToken();
    return (
        <div style={{ display: "flex", flexDirection: "row", borderWidth: 1, borderColor: token.colorBorder, padding: token.paddingXS, borderStyle: "solid", marginBottom: 8, alignItems: "center" }}>
            <div >
                <span className='handle' style={{ cursor: "pointer" }}>
                    <Button type='link'>         <DragOutlined /></Button>
                </span>
            </div>
            <div style={{ marginRight: 16 }}>
                {link.icon &&
                    <image src={link.icon}></image>
                }
                {/* <image ></image>  {link.icon} */}
            </div>
            <div style={{ flexGrow: 1 }}>
                {link.title}
            </div>
            <div>
                <Space>
                    <Button type='link' onClick={e => onEdit(link)}>Edit</Button>
                    <Button type='link' onClick={e => onRemove(link)}>Delete</Button>
                </Space>

            </div>

        </div>
    )
})


const Header = memo(({ link, onRemove, onEdit }) => {
    const { token } = useToken();

    return (
        <div style={{ display: "flex", flexDirection: "row", borderWidth: 1, borderColor: token.colorBorder, padding: token.paddingXS, borderStyle: "dashed", marginBottom: 8, alignItems: "center" }}>
            <div >
                <span className='handle' style={{ cursor: "pointer" }}>
                    <Button type='link'>         <DragOutlined /></Button>
                </span>
            </div>
            <div style={{ flexGrow: 1, textAlign: "center" }}>
                {link.title}
            </div>
            <div>
                <Space>
                    <Button type='link' onClick={e => onEdit(link)}>Edit</Button>
                    <Button type='link' onClick={e => onRemove(link)}>Delete</Button>
                </Space>
            </div>

        </div>
    )
})


export default memo(SocicalLinks);