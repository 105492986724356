import { memo, useEffect, useRef, useState } from 'react';
import { Col, Divider, Row, Button, Space, Tabs, Form, Input } from 'antd';
import Theme from "./theme"
import Theme2 from "./theme2"
import { BackgroundPicker, ButtonStylePicker, FontPicker,ImagePicker } from "components/picker"
import api from "api"
import { Routes, Route, useParams } from 'react-router-dom';
import { useMessage } from "hooks/message"
import { previewIframeUrl } from "config"
import PagePreview from "components/page-preview"
const ThemeEditor = () => {
  var { id } = useParams();
  console.log("id", id)

  const message = useMessage();

  const [theme, setTheme] = useState(Theme);
  const [themeData, setThemeData] = useState({});
  const [form] = Form.useForm();
  const preview = useRef();

  useEffect(() => {


    loadData();
  }, [])


  const loadData = async () => {
    if (id != 0) {

      var res = await api.get("/api/master/theme/get", { params: { id: id } });
      setTheme(res.data.data.theme);
      setThemeData(res.data.data);
      changeTheme(res.data.data.theme);
      delete res.data.data.theme;
      form.setFieldsValue({ ...res.data.data });




    } else {
      //set default theme
      setTheme(Theme);
      var defaultTheme = { name: "new theme" };
      setThemeData(defaultTheme);
      changeTheme(Theme);
      form.setFieldsValue(defaultTheme);
    }

    var res = await api.get("/api/master/theme/getdefaultlink");
    var profile = res.data.data.profile;

    postMessage("setprofile", profile)
    postMessage("setsocicallinks", res.data.data.socical_links ?? [])
    postMessage("setpagelinks", res.data.data.page_links)


  }




  const changeTheme = (theme) => {
    ;
    postMessage("settheme", theme);
  }

  const onChange = (name, css) => {
    var theme2 = { ...theme };
    theme2[name] = css;
    changeTheme(theme2);
    setTheme(theme2);
  }


  const save = async () => {
    var formData = form.getFieldsValue(true);
    var postData = { ...formData, theme: theme };
    var { data } = await api.post("/api/master/theme/save", postData);
    message.showSuccess('Đã lưu xong');

  }


  const getPreviewIframe = () => {
    const iframe = document.querySelector("#previewIframe");
    return iframe;
  }

  const postMessage = (action, data) => {
    // const iframe = getPreviewIframe();
    // iframe.contentWindow.postMessage({ from: "bio_admin", action: action, data: data }, "*");
    preview.current.postMessage({ from: "bio_admin", action: action, data: data })

  }


  const renderDesign = () => {
    return (
      <div>

        <Space direction='vertical' style={{ width: "100%" }}>


          <BackgroundPicker themeData={theme.background} themeData2={theme.background} onChange={css => { onChange("background", css) }}>

          </BackgroundPicker>

          <ButtonStylePicker themeData={theme.button} onChange={css => { onChange("button", css) }}>

          </ButtonStylePicker>

          <FontPicker themeData={theme.font} onChange={css => { onChange("font", css) }}>

          </FontPicker>
        </Space>
      </div>
    )
  }

  const renderInfo = () => {

    return <div>
      <Form

        form={form}
        name="control-hooks"

      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name={"thumbnail"} label="Thumbnail">
          <ImagePicker file_type="theme_thumbnail"/>
        </Form.Item>


      </Form>
    </div>
  }


  return (
    <Row gutter={16}>
      <Col className="gutter-row" span={8}>
        <div style={{ position: "fixed", height: "90vh" }}  >
          <PagePreview id={"default"} ref={preview}></PagePreview>
          {/* 
        
          <iframe id="previewIframe" src={previewIframeUrl} title='bio design' style={{ height: "100%", width: "100%", border: "none", borderRadius: 0, }}></iframe> */}
        </div>
      </Col>
      <Col className="gutter-row" span={16}>



        <div >

          <Tabs defaultActiveKey="design"

            tabBarExtraContent={<Button onClick={save} type='primary'>Save</Button>}

            items={[
              {
                key: 'design',
                label: 'Design',
                children: <> {renderDesign()}</>,
              },
              {
                key: 'info',
                label: 'General',
                children: <> {renderInfo()}</>,
              },
            ]} />

        </div>

      </Col>
    </Row>
  )

}

export default memo(ThemeEditor);