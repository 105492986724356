import React from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, UnorderedListOutlined, FileImageOutlined } from '@ant-design/icons';
import { Flex, Layout, Menu, theme, Dropdown, Space } from 'antd';

import { ThemeEditor, ThemeList, ImageManagement, ImageAdd } from "pages/master";
import DesignPage from 'pages/user/design';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { AuthenticationProvider, useAuthentication } from "hooks/authentication"
const { Header, Content, Footer, Sider } = Layout;

const { useToken } = theme;

const App = () => {
    const { login, auth, logout } = useAuthentication();
    const {
        token: { colorBgContainer, colorPrimary },
    } = theme.useToken();
    const navigate = useNavigate();


    const items = [
        {
            key: 'logout',
            label: (
                <a onClick={logout}>
                    Logout
                </a>
            ),
        },

    ];
    return (

        <Layout style={{ minHeight: "100vh", padding: 0 }}>

            <Layout>
                <Header style={{
                    padding: 0, background: "linear-gradient(to right, #01E0FF, #005AC1)",
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    borderBottomColor: colorPrimary,
                    borderBottomWidth: 1,
                    backgroundColor: colorPrimary
                }} >
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", flexDirection: "row" }}>
                        <div style={{ marginRight: 16 }}>
                            <Dropdown menu={{ items }}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <span style={{ color: "white" }}>
                                            {auth.full_name}
                                        </span>

                                    </Space>
                                </a>
                            </Dropdown>

                        </div>
                    </div>
                </Header>
                <Content style={{ padding: 8, backgroundColor: "white" }}>
                    <div style={{ minHeight: 360, }}>
                        <Routes>
                            <Route path="design/:id" element={<DesignPage />} />
                            <Route path="/" element={<DesignPage link_id={"default"} />}>
                            </Route>
                        </Routes>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center'}}>Cubetech 2023</Footer>
            </Layout>
        </Layout>

    );
};

export default App;