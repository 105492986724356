import { memo, useEffect, useState } from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Button, Radio, Space, Divider, Row, Col } from "antd";
import tinycolor from "tinycolor2";
import { ImagePicker, ColorPicker } from "components/picker"
import { SelectButton } from "components"
import _ from "lodash"

const getStyle = (style, color) => {
    var css = {};
    css.borderRadius = style.borderRadius;
    css.borderStyle = style.borderStyle;
    css.borderWidth = style.borderWidth;
    css.borderColor = color;
    if (style.bgType == "fill") {
        css.backgroundColor = color;
    }

    return css;
}

const ButtonStylePicker = ({ onChange, themeData }) => {
    const [data, setData] = useState({ bioMeta: {} })
    const styles1 = [

        { id: "btn1", borderRadius: "30px", borderStyle: "solid", borderWidth: "1px", bgType: "fill" },
        { id: "btn2", borderRadius: "8px", borderStyle: "solid", borderWidth: "1px", bgType: "fill" },
        { id: "btn3", borderRadius: "0px", borderStyle: "solid", borderWidth: "1px", bgType: "fill" },
        { id: "btn4", borderRadius: "30px", borderStyle: "solid", borderWidth: "1px", bgType: "transparent" },
        { id: "btn5", borderRadius: "8px", borderStyle: "solid", borderWidth: "1px", bgType: "transparent" },
        { id: "btn6", borderRadius: "0px", borderStyle: "solid", borderWidth: "1px", bgType: "transparent" },
    ]

    const [styles, setStyles] = useState(styles1);



    useEffect(() => {
        if (_.isEqual(data.bioMeta, {})) {
            return;
        }

        var css = getData();
        onChange(css);
    }, [data])
    useEffect(() => {
        if (!themeData?.bioMeta) {
            return;
        }
        if (_.isEqual(themeData.bioMeta, {})) {
            return;
        }
        if (!_.isEqual(themeData.bioMeta, data.bioMeta)) {
            setData(themeData);
        }

    }, [themeData])







    const getData = () => {
        var css = {};

        var meta = data.bioMeta;
        css = getStyle(meta, meta.color)
        css.bioMeta = data.bioMeta;
        return css;

    }


    const setMetaData = (name, value) => {
        var newData = { ...data };
        if (!newData.bioMeta) {
            newData.bioMeta = {};
        }
        newData.bioMeta[name] = value;
        newData = toDefault(newData);
        setData(newData);
    }

    const toDefault = (data) => {
        return data;
    }


    const selectStyle = (style) => {
        var data2 = { ...data };
        var newMeta = { ...data2.bioMeta, ...style };
        data2.bioMeta = newMeta;
        setData(data2);

    }


    const colorChange = (e) => {
        setMetaData("color", e.toHexString())
    }


    return (
        <Card title="Button">
            <Row gutter={[16, 16]}>


                {styles.map(style => {
                    return (<ButtonStyle selected={data.bioMeta?.id == style.id} style={style} color={data.bioMeta?.color} onClick={e => selectStyle(style)}></ButtonStyle>)
                })}
            </Row>

            <div>
                <label>Color</label>
            </div>

            <ColorPicker
                onChange={colorChange}

                value={data.bioMeta?.color}


            />

        </Card>
    )
}

const ButtonStyle = ({ style, color, selected, onClick }) => {

    return (

        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <SelectButton selected={selected} onClick={onClick}>

                <div style={{ ...getStyle(style, color), width: "100%", height: 48 }} >

                </div>
            </SelectButton>
        </Col>

    )
}

export default memo(ButtonStylePicker);