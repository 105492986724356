
var apiPath ="http://192.168.1.225:3068/";
var previewIframeUrl ="http://192.168.1.225:3000/link";
var bioLinkUrl ="http://192.168.1.225:3000/link/";
var socketPath ="http://192.168.1.225:3068/";

apiPath ="https://bioapi.myitemsinventory.app/";
previewIframeUrl ="https://biolink.myitemsinventory.app/link";
bioLinkUrl ="https://biolink.myitemsinventory.app/link/";
socketPath ="https://bioapi.myitemsinventory.app/";
export {apiPath,previewIframeUrl,bioLinkUrl,socketPath}