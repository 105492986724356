import { memo, useEffect, useState } from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Button, Radio, Space, Divider } from "antd";
import tinycolor from "tinycolor2";
import { ImagePicker, ColorPicker } from "components/picker"
import _ from "lodash"

const BackgroundPicker = ({ onChange, themeData }) => {

    const [data, setData] = useState({ bioMeta: {} })

    useEffect(() => {

        if (_.isEqual(data.bioMeta, {})) {
            return;
        }
        // if (_.isEqual(data.bioMeta, themeData.bioMeta)) {
        //     return;
        // }

        var css = getData();
        onChange(css);
    }, [data])

    useEffect(() => {

        if (!themeData?.bioMeta) {
            return;
        }
        if (_.isEqual(themeData.bioMeta, {})) {
            return;
        }

        if (!_.isEqual(themeData.bioMeta, data.bioMeta)) {
            setData(themeData);
        }


    }, [themeData])




    const setColorType = (bgType) => {
        setMetaData("colorType", bgType)
    }




    const setMetaData = (name, value) => {

        var newData = { ...data };
        if (!newData.bioMeta) {
            newData.bioMeta = {};
        }
        newData.bioMeta[name] = value;
        newData = toDefault(newData);
        setData(newData);
    }




    const bgTypeClick = (e) => {
        setMetaData("bgType", e.target.value)

    }

    const colorChange = (e) => {
        setMetaData("color", e.toHexString())
    }

    const toDefault = (d) => {

        if (!d.bioMeta.color) {
            d.bioMeta.color = "#FFD7BA"
        }
        if (!d.bioMeta.colorType) {
            d.bioMeta.colorType = "flat"
        }
        return d;
    }

    const getData = () => {
        var css = {};
        css.bioMeta = data.bioMeta;
        var meta = data.bioMeta ?? {};
        if (meta.bgType == "color") {
            var color = tinycolor(meta.color);
            var endColor = color.brighten(50).toHexString();

            switch (meta.colorType) {
                case "flat":
                    css.backgroundColor = `${meta.color}`
                    break;
                case "radial":
                    css.background = `radial-gradient(circle, ${endColor} 0%, ${meta.color} 100%)`;
                    break;
                case "linear-up":
                    css.background = `linear-gradient(0deg, ${meta.color} 0%, ${endColor} 100%)`
                    break;
                case "linear-down":
                    css.background = `linear-gradient(180deg, ${meta.color} 0%, ${endColor} 100%)`
                    break;
                case "linear-left":
                    css.background = `linear-gradient(90deg, ${meta.color} 0%, ${endColor} 100%)`
                    break;
                case "linear-right":
                    css.background = `linear-gradient(270deg, ${meta.color} 0%, ${endColor} 100%)`
                    break;


                default:
                    break;
            }
        }
        if (meta.bgType == "image") {
            css.background = `url("${meta.bgImage}")`;
            css.backgroundSize = "cover";
            css.backgroundPosition = 'center'

        }

        return css;

    }

    const onBgSelect = (bg) => {
        setMetaData("bgImage", bg);
    }


    return (
        <Card title="Background">

            <Space direction="vertical">
                <Radio.Group value={data.bioMeta?.bgType} onChange={bgTypeClick}>
                    <Radio.Button value="color">Color</Radio.Button>
                    <Radio.Button value="image">Image</Radio.Button>
                    {/* <Radio.Button value="video">Video</Radio.Button> */}
                </Radio.Group>
                {data.bioMeta?.bgType == "color" &&

                    <div>
                        <Space direction="vertical">
                            <label>Color</label>
                            <ColorPicker
                                onChange={colorChange}

                                value={data.bioMeta?.color}


                            />
                            <Radio.Group value={data.bioMeta?.colorType} onChange={(e) => setColorType(e.target.value)}>
                                <Radio value="flat">Flat</Radio>
                                <Radio value="radial">Radial</Radio>
                                <Radio value="linear-up">Linear Up</Radio>
                                <Radio value="linear-down">Linear Down</Radio>
                                <Radio value="linear-left">Linear Left</Radio>
                                <Radio value="linear-right">Linear Right</Radio>
                            </Radio.Group>

                        </Space>
                    </div>
                }
                {data.bioMeta?.bgType == "image" &&
                    <div>
                        <ImagePicker onChange={onBgSelect} value={data.bioMeta?.bgImage} file_type="background"> </ImagePicker>
                    </div>

                }


            </Space>
        </Card>
    )
}

export default memo(BackgroundPicker);
// export default BackgroundPicker;