import { memo, useEffect, useState } from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Button, Radio, Space, Divider, Flex, Row, Col } from "antd";
import tinycolor from "tinycolor2";
import { ImagePicker, ColorPicker } from "components/picker"
import { SelectButton } from "components"
import _ from "lodash"

const getStyle = (style, color) => {
    var css = {};
    css.fontFamily = style.fontFamily;
    css.color = style.color;


    return css;
}

const FontPicker = ({ themeData, onChange }) => {
    const [data, setData] = useState({ bioMeta: {} })
    const fonts = [

        { id: "btn1", fontFamily: "Inter, sans-serif;", name: "Inter", },
        { id: "btn2", fontFamily: "Poppins, sans-serif", name: "Poppins", },
        { id: "btn3", fontFamily: "EB Garamond, sans-serif", name: "EB Garamond", },
        { id: "btn4", fontFamily: "Teko, sans-serif", name: "Teko", },
        { id: "btn5", fontFamily: "Balsamiq Sans, cursive", name: "Balsamiq Sans", },
        { id: "btn6", fontFamily: "Kite One, cursive", name: "Kite One", },
    ]

    const [styles, setStyles] = useState(fonts);


    useEffect(() => {
        if (_.isEqual(data.bioMeta, {})) {

            return;
        }

        var css = getData();
        onChange(css);
    }, [data])

    useEffect(() => {

        if (!themeData?.bioMeta) {
            return;
        }
        if (_.isEqual(themeData.bioMeta, {})) {
            return;
        }
        if (!_.isEqual(themeData.bioMeta, data.bioMeta)) {
            setData(themeData);
        }

    }, [themeData])









    const getData = () => {
        var css = {};

        var meta = data.bioMeta;
        css = getStyle(meta, meta.color)
        css.bioMeta = data.bioMeta;
        return css;

    }


    const setMetaData = (name, value) => {
        console.log("set meta ", name, value)
        var newData = { ...data };
        if (!newData.bioMeta) {
            newData.bioMeta = {};
        }
        newData.bioMeta[name] = value;
        newData = toDefault(newData);
        setData(newData);
    }

    const toDefault = (data) => {
        return data;
    }


    const selectStyle = (style) => {
        var data2 = { ...data };
        var newMeta = { ...data2.bioMeta, ...style };
        data2.bioMeta = newMeta;
        setData(data2);

    }


    const colorChange = (e) => {
        setMetaData("color", e.toHexString())
    }


    return (
        <Card title="Fonts">
            <Row gutter={[16, 16]}>
                {styles.map(font => {
                    return (<FontSelect name={font.name} selected={data.bioMeta?.id == font.id} fontFamily={font.fontFamily} color={data.bioMeta?.color} onClick={e => selectStyle(font)}></FontSelect>)
                })}
            </Row>
            <div>
                <label>Color</label>
            </div>
            <ColorPicker
                onChange={colorChange}
                value={data.bioMeta?.color}

            />

        </Card>
    )
}

const FontSelect = ({ fontFamily, color, name, selected, onClick }) => {

    return (

        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <SelectButton selected={selected} onClick={onClick}  >



                <Flex style={{ fontFamily: fontFamily, width: "100%", height: 48 }} justify="center" align={"center"}>
                    <span>
                        {name}
                    </span>
                </Flex>




            </SelectButton>
        </Col>
    )
}

export default memo(FontPicker);