import { memo, useEffect, useState } from "react"
import { Button, Card, Space, Table, Tag } from 'antd';
import api from "api"
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { useMessage } from "hooks/message"
const ThemeList = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const message = useMessage();
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        var { data } = await api.get("/api/master/theme/list");
        setData(data.data);
    }
    const goToEdit = (id) => {
        navigate(`/theme/${id}`)
    }

    const deleteItem = async (id) => {
        var confirm = await message.showConfirm();
        if (confirm) {
            await api.post("/api/master/theme/delete", { id: id });
            loadData();
        }
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },

        {
            title: <Button onClick={e => navigate("/theme/0")}>Thêm mới</Button>,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={e => goToEdit(record.id)}>Edit</Button>
                    <Button type="link" onClick={e => deleteItem(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];



    return (
        <div>
            <Space direction="vertical" style={{ width: "100%" }}>


                <Card>

                </Card>
                <Card>
                    <Table columns={columns} dataSource={data} />
                </Card>
            </Space>
        </div>
    )
}

export default memo(ThemeList)
