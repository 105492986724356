import { memo, useEffect, useState, useRef, createRef } from 'react';
import { Col, Divider, Row, Button, Space, Tabs, Form, Input, Flex, Card, } from 'antd';
import Theme from "./theme"

import { BackgroundPicker, ButtonStylePicker, FontPicker } from "components/picker"
import api from "api"
import { Routes, Route, useParams } from 'react-router-dom';
import { useMessage } from "hooks/message"
import { SelectButton, QrUrlModal } from "components"
import _ from "lodash"
import { ImagePicker } from 'components/picker';
import SocicalLinks from "components/link-design/SocicalLinks"
import PageLinks from "components/link-design/PageLinks"
import { previewIframeUrl, bioLinkUrl, socketPath } from "config"
import { LinkOutlined } from '@ant-design/icons';
import { io } from "socket.io-client";
import PagePreview from "components/page-preview"

const socket = io(socketPath);

socket.on("connect", () => {
  console.log(socket.id); // x8WIv7-mJelg7on_ALbx
});


var saveProfileTimeOut = null;
var updateLinkUrlTimeOut = null;
var updateSEOTimeOut = null;
const ThemeEditor = ({ id }) => {




  const message = useMessage();

  const [theme, setTheme] = useState(Theme);
  const [themeID, setThemeID] = useState("");
  const [themeList, setThemeList] = useState([]);
  const [showDesign, setShowDesign] = useState(false);

  const [themeData, setThemeData] = useState({});
  const [form] = Form.useForm();
  const [profileForm] = Form.useForm();
  const [formSeo] = Form.useForm();
  const [socicalLinks, setSocicalLinks] = useState([]);
  const [socicalPosition, setSocicalPosition] = useState("top");
  const [pageLinks, setPageLinks] = useState([]);
  const [url, setUrl] = useState([]);
  const preview = useRef("previewref");



  useEffect(() => {
    const load = async () => {
      await loadData();
      await loadListTheme();
    }
    load();
  }, [])


  const loadListTheme = async () => {
    var res = await api.get("/api/user/link/themelist", { params: { id: id } });
    setThemeList(res.data.data);
  }


  const loadData = async () => {

    var res = await api.get("/api/user/link/get", { params: { id: id } });
    if (res.data.code != 1) {
      return;
    }
    setTheme(res.data.data.theme);
    setThemeData(res.data.data);
    changeTheme(res.data.data.theme);
    setThemeID(res.data.data.theme_id)
    setUrl(res.data.data.url)
    if (res.data.data.theme_type == "user") {
      setShowDesign(true);
    } else {
      setShowDesign(false);
    }


    var profile = res.data.data.profile;

    profileForm.setFieldsValue(profile);

    postMessage("setprofile", profile)
    //links
    setSocicalLinks(res.data.data.socical_links);
    postMessage("setsocicallinks", res.data.data.socical_links ?? [])

    setSocicalPosition(res.data.data.socical_position)
    postMessage("setsocicalposition", res.data.data.socical_position)

    setPageLinks(res.data.data.page_links)
    postMessage("setpagelinks", res.data.data.page_links)

    formUrl.setFieldsValue({ url: res.data.data.url })
    formSeo.setFieldsValue(res.data.data.seo ?? {})



  }




  const changeTheme = (theme) => {

    postMessage("settheme", theme)


  }

  const onChange = (name, css) => {

    if (_.isEqual(theme[name], css)) {
      return;
    }

    console.log("onchange ", name, css)
    var theme2 = { ...theme };
    theme2[name] = css;
    changeTheme(theme2);
    setTheme(theme2);
    saveUserTheme(theme2);

  }

  const saveUserTheme = async (theme2) => {
    var currentTheme = themeList.find(x => x.id == themeID);
    if (!currentTheme) {
      return;
    }
    var postData = { ...{ id: currentTheme.id }, theme: theme2 };
    var { data } = await api.post("/api/user/link/savetheme", postData);

  }




  const themeSelect = (t) => {

    setThemeID(t.id);
    setTheme(t.theme);
    changeTheme(t.theme);

    if (t.theme_type == "user") {
      setShowDesign(true);
    } else {
      setShowDesign(false);
    }



  }

  const themeSelectClick = async (t) => {
    themeSelect(t);
    await api.post("/api/user/link/updatethemeid", { id: id, theme_id: t.id })

  }

  const onProfileChange = () => {
    var formData = profileForm.getFieldsValue(true);
    postMessage("setprofile", formData)

    saveProfile(formData);
  }

  const saveProfile = async (p) => {
    if (saveProfileTimeOut)
      clearTimeout(saveProfileTimeOut);
    saveProfileTimeOut = setTimeout(async () => {
      await api.post("/api/user/link/updateprofile", { id: id, profile: p })
    }, 1000)



  }

  const onSocicalLinksChange = (links) => {
    setSocicalLinks(links);
    saveSocicalLinks(links);

    postMessage("setsocicallinks", links)
  }
  const saveSocicalLinks = async (links) => {
    await api.post("/api/user/link/updatesocicallinks", { id: id, links: links })
  }
  const saveSocicalPosition = async (position) => {
    await api.post("/api/user/link/updatesocicalposition", { id: id, position: position })
  }


  const savePageLinks = async (links) => {
    await api.post("/api/user/link/updatepagelinks", { id: id, links: links })
  }


  const socicalPositionChange = (position) => {
    setSocicalPosition(position)
    postMessage("setsocicalposition", position)
    saveSocicalPosition(position);
  }


  const onPageLinksChange = (links) => {
    setPageLinks(links);
    savePageLinks(links);
    postMessage("setpagelinks", links)

  }

  const postMessage = (action, data) => {
    preview.current.postMessage({ from: "bio_admin", action: action, data: data })

  }

  const renderDesign = () => {
    const lstIconMap =
      [
        { title: "Email", id: "email", icon: <i className="fa-solid fa-envelope"></i> },
        { title: "Facebook", id: "facebook", icon: <i className="fa-brands fa-facebook"></i> },
        { title: "Instagram", id: "instagram", icon: <i className="fa-brands fa-instagram"></i> },
        { title: "Tiktok", id: "tiktok", icon: <i className="fa-brands fa-tiktok"></i> },
        { title: "Youtube", id: "youtube", icon: <i className="fa-brands fa-youtube"></i> },
        { title: "Spotify", id: "spotify", icon: <i className="fa-brands fa-spotify"></i> },
        { title: "Twitter", id: "twitter", icon: <i className="fa-brands fa-x-twitter"></i> },
      ]
    return (
      <div>

        <Space direction='vertical' style={{ width: "100%" }}>
          <Card title="Profile">
            <Form

              form={profileForm}
              name="control-profileForm"
              layout='vertical'

            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16} >
                  <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input onChange={onProfileChange} />
                  </Form.Item>
                  <Form.Item name="bio" label="Bio">
                    <Input onChange={onProfileChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item name="avatar" label="Avatar">
                    <ImagePicker onChange={onProfileChange} crop={{ cropShape: "round" }} file_type="avatar" hiddenGallery></ImagePicker>
                  </Form.Item>

                </Col>
              </Row>


            </Form>
          </Card>
          <Card title="Themes">
            
            <Row gutter={[16, 16]}>
              {themeList.map(t => {
                let theme = t.theme.background.backgroundColor ?? t.theme.background.background
                return (
                  <Col xs={8} sm={8} md={8} lg={4} xl={3}>
                    <SelectButton selected={t.id == themeID} onClick={e => { themeSelectClick(t) }}>
                      <Flex style={{ width: "100%", height: 150, background: `${theme}`, backgroundSize: "cover", backgroundPosition: "center", borderRadius: 4, padding: 8, textAlign: "center" }} justify="center" align="center">
                        <span style={{ color: `${t.theme.font.color}` }}>
                          {t.name ?? "no name"}
                        </span>
                      </Flex>

                    </SelectButton>
                  </Col>
                )
              })}
            </Row>
          </Card>
          {showDesign &&

            <Space direction='vertical' style={{ width: "100%" }}>
              <BackgroundPicker themeData={theme.background} onChange={css => { onChange("background", css) }}>
              </BackgroundPicker>
              <ButtonStylePicker themeData={theme.button} onChange={css => { onChange("button", css) }}>

              </ButtonStylePicker>

              <FontPicker themeData={theme.font} onChange={css => { onChange("font", css) }}>

              </FontPicker>
            </Space>
          }
          <Card title="Position to display socials">

            <Row gutter={[16, 16]}>
              <Col xs={8} sm={8} md={8} lg={4} xl={3}>
                <SelectButton selected={socicalPosition == "top"} onClick={e => { socicalPositionChange("top") }}>
                  <Flex style={{ width: "100%", height: 150, background: "linear-gradient(to bottom, #01E0FF, #01A6E4)", borderRadius: 4, padding: 8, textAlign: "center" }} vertical justify="space-between" align="center">
                    Profile

                    <div style={{ width: '100%', display: 'flex', justifyContent: "space-evenly" }}>
                      {socicalLinks?.map(s => {
                        return lstIconMap.map(ic => {
                          if (s.id === ic.id) {
                            return ic.icon
                          }
                        })
                      }).flat()}
                    </div>

                    Content
                  </Flex>
                </SelectButton>
              </Col>

              <Col xs={8} sm={8} md={8} lg={4} xl={3}>
                <SelectButton selected={socicalPosition == "bottom"} onClick={e => { socicalPositionChange("bottom") }}>
                  <Flex style={{ width: "100%", height: 150, background: "linear-gradient(to bottom, #01E0FF, #01A6E4)", borderRadius: 4, padding: 8, textAlign: "center" }} vertical justify="space-between" align="center">
                    <span>Profile</span>
                    <span>Content</span>

                    <div style={{ width: '100%', display: 'flex', justifyContent: "space-evenly" }}>
                      {socicalLinks?.map(s => {
                        return lstIconMap.map(ic => {
                          if (s.id === ic.id) {
                            return ic.icon
                          }
                        })
                      }).flat()}
                    </div>

                  </Flex>
                </SelectButton>
              </Col>
            </Row>
          </Card>
        </Space>
      </div>
    )
  }

  const renderLink = () => {

    return <div>
      <Space direction='vertical' style={{ width: "100%" }}>

        <Card title="Socical">
          <SocicalLinks value={socicalLinks} onChange={onSocicalLinksChange}></SocicalLinks>
        </Card>
        <Card title="Links">
          <PageLinks value={pageLinks} onChange={onPageLinksChange}></PageLinks>

        </Card>
      </Space>
    </div>
  }

  const onLinkUrlChange = (e) => {
    formUrl.setFields([{ name: "url", errors: [] }]);
    setUrlUpdateStatus("updating");
    updateLinkUrl(e.target.value)
  }

  const [formUrl] = Form.useForm();
  const [urlUpdateStatus, setUrlUpdateStatus] = useState("done")

  const updateLinkUrl = (linkUrl) => {
    if (updateLinkUrlTimeOut) {

      clearTimeout(updateLinkUrlTimeOut);
    }
    updateLinkUrlTimeOut = setTimeout(async () => {
      var { data } = await api.post("/api/user/link/updatepageurl", { id: id, url: linkUrl });
      if (data.code == 1) {
        setUrl(data.data.url);
        setUrlUpdateStatus("done");
      } else {
        formUrl.setFields([{ name: "url", errors: [data.error] }])
        setUrlUpdateStatus("error");
      }
    }, 1000);
  }

  const onFormSeoChange = (data) => {

    var seo = formSeo.getFieldsValue(true);
    console.log("chnage", seo)
    if (updateSEOTimeOut) {

      clearTimeout(updateSEOTimeOut);
    }
    updateSEOTimeOut = setTimeout(async () => {
      var { data } = await api.post("/api/user/link/updatepageseo", { id: id, seo: seo });

    }, 1000);
  }

  const renderSetting = () => {
    return (<div>
      <Space direction='vertical' style={{ width: "100%" }}>


        <Card title="Link">
          <Form layout='vertical' form={formUrl}>
            <Form.Item name="url" label="Url" >
              <Input addonBefore={"/link/"} onChange={onLinkUrlChange}
                addonAfter={<>
                  {urlUpdateStatus == "done" &&
                    <Button type='link' size='small' onClick={e => window.open(`${bioLinkUrl}${url}`)}>   <LinkOutlined></LinkOutlined></Button>
                  }

                  {urlUpdateStatus == "updating" &&
                    <>Updating</>
                  }

                  {urlUpdateStatus == "error" &&
                    <>Error</>
                  }



                </>} />
            </Form.Item>
          </Form>

        </Card>

        <Card title="SEO">
          <Form layout='vertical' form={formSeo} onChange={onFormSeoChange}>
            <Form.Item name="title" label="Title">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
            <Form.Item name="keywords" label="Keywords(Separate with commas)">
              <Input />
            </Form.Item>
          </Form>

        </Card>

      </Space>
    </div>)
  }


  return (
    <Row gutter={16}>
      <Col className="gutter-row" xs={0} sm={0} md={0} lg={8} xl={8}  >
        <div style={{ height: "100vh", display: "flex", justifyContent: 'center' }}  >
          <PagePreview id={id} ref={preview}></PagePreview>
        </div>
      </Col>
      <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} style={{ backgroundColor: "#f9f9f9" }}>



        <div >

          <Tabs defaultActiveKey="design"

            tabBarExtraContent={
              <Space>
                <QrUrlModal url={`${bioLinkUrl}${id}?mode=preview`} title="Preview" description="Quét mã QR để xem trước"></QrUrlModal>
                <Button onClick={e => { window.open(`${bioLinkUrl}${url}`) }} type='link'><LinkOutlined /></Button>
              </Space>
            }
            items={[
              {
                key: 'design',
                label: 'Design',
                children: <> {renderDesign()}</>,
              },
              {
                key: 'links',
                label: 'Links',
                children: <> {renderLink()}</>,
              },
              {
                key: 'settingtab',
                label: 'Settings',
                children: <> {renderSetting()}</>,
              },
            ]} />

        </div>

      </Col>
    </Row >
  )

}

const MemoThemeEditor = memo(ThemeEditor);

const ThemeEditorPage = ({ link_id }) => {
  const params = useParams();
  const [id, setID] = useState();
  useEffect(() => {
    var currentID = link_id ? link_id : params.id;
    if (currentID != "default") {
      setID(currentID);
    }
    else {
      api.get("/api/user/link/getdefaultlink").then(({ data }) => {
        setID(data.data);
      })
    }


  }, [])

  if (!id)
    return null;



  return <MemoThemeEditor id={id}></MemoThemeEditor>
}

export default memo(ThemeEditorPage);