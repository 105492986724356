import React, { Component, useState, useEffect, createContext, useContext } from 'react';

import { useNavigate } from "react-router-dom";
const AuthenticationContext = createContext();

const AuthenticationProvider = ({ children }) => {

    const [auth, setAuth] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate();
    const login = (user) => {
        setAuth(user);
        navigate("/")

    }

    useEffect(() => {
        console.log("authauthauthauth", auth)
    }, [auth])

    const logout = () => {
        setAuth(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }


    useEffect(() => {
        var userStr = localStorage.getItem("user");
        if (userStr) {
            var user = JSON.parse(userStr);
            setAuth(user);

        }
        setIsLoading(false)
    }, [])

    return (
        <AuthenticationContext.Provider
            value={{ login: login, logout: logout, auth: auth }}
        >
            {isLoading ?
                <div>loading</div>
                :
                <>
                    {children}
                </>
            }


        </AuthenticationContext.Provider>
    );

}

const useAuthentication = () => {
    const context = useContext(AuthenticationContext);
    return context;
}


export { AuthenticationProvider, useAuthentication }
