import { memo, useEffect, useState } from "react";
import { Button, theme } from 'antd';
const { useToken } = theme;

const SelectButton = ({ children, selected, onClick }) => {
    const { token } = useToken();

    var style = {};
    if (selected) {
        style = {
            borderColor: token.colorPrimary,
        }
    } else {
        style = {
            borderColor: "transparent",
        }
    }
    return (
        <div onClick={onClick} style={{ ...style, padding: 2, borderWidth: 1, borderStyle: "solid", cursor: "pointer", borderRadius: token.borderRadius }} >
            {children}
        </div>
    )

}

export default memo(SelectButton)