import React, { useState, forwardRef, useImperativeHandle, useRef, memo, useEffect } from 'react';
import { previewIframeUrl, bioLinkUrl, socketPath } from "config"
import { io } from "socket.io-client";
import { Flex, Select, Space } from 'antd';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faBatteryFull, faWifi } from '@fortawesome/free-solid-svg-icons';
import { QrUrlModal } from "components"
import {
    QrcodeOutlined,
} from '@ant-design/icons';
import './pagePreview.css';

const socket = io(socketPath);

socket.on("connect", () => {
    console.log(socket.id);
});

const PagePreview = forwardRef(({ id }, ref) => {
    const [device, setDevice] = useState('iPhone X');

    const getPreviewIframe = () => {
        const iframe = document.querySelector("#previewIframe");
        return iframe;
    }

    useImperativeHandle(ref, () => ({
        postMessage(data) {

            const iframe = getPreviewIframe();
            if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(data, "*");
            }
            console.log("socket emit", data);

            socket.emit("preview_link", { ...data, to: id })
        }
    }));

    const statusBarStyle = {
        height: '20px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const handleDeviceChange = (value) => {
        setDevice(value);
    };

    return (
        <div style={{ position: 'fixed', top: '3rem', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>



            <div style={{ margin: '9px 0' }}>
                <Space>


                    <Select
                        defaultValue="iPhone X"
                        style={{
                            width: 160,
                        }}
                        onChange={handleDeviceChange}
                        options={[
                            {
                                value: 'iPhone X',
                                label: 'iPhone X',
                            },
                            {
                                value: 'iPhone 8',
                                label: 'iPhone 8',
                            },
                            {
                                value: 'HTC One',
                                label: 'HTC One',
                            },
                            {
                                value: 'Galaxy Note 8',
                                label: 'Galaxy Note 8',
                            },
                            {
                                value: 'Samsung Galaxy S5',
                                label: 'Samsung Galaxy S5',
                            },
                        ]}
                    />

                    <QrUrlModal url={`${bioLinkUrl}${id}?mode=preview`} title={<QrcodeOutlined style={{ fontSize: 24 }} />} description="Quét mã QR để xem trước"></QrUrlModal>
                </Space>
            </div>
            <DeviceFrameset device={device} color='silver'>
                <div style={statusBarStyle}>
                    <div>
                        <FontAwesomeIcon icon={faSignal} style={{ margin: "0 1rem" }} />
                        <FontAwesomeIcon icon={faSignal} />
                    </div>
                    <div>{currentTime}</div>
                    <div>
                        <FontAwesomeIcon icon={faWifi} />
                        <FontAwesomeIcon icon={faBatteryFull} style={{ margin: "0 1rem" }} />
                    </div>
                </div>
                <iframe title='phoneFrame' style={{ height: "100%", width: "100%" }} id="previewIframe" src={`${previewIframeUrl}/${id}?mode=preview_embed`} frameBorder={0}></iframe>
            </DeviceFrameset>
        </div >
    );
});

export default memo(PagePreview);
