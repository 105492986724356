import React, { useState, memo, useEffect, useRef } from 'react';
import { Button, Modal, Tabs, Image, Space, theme, Flex, Form, Input } from 'antd';
import { SelectButton } from "components"
import { FullscreenOutlined, UploadOutlined, DragOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ReactSortable } from "react-sortablejs";
import FormItem from 'antd/es/form/FormItem';
import _ from "lodash"
import { toArray } from "common"
import { useMessage } from 'hooks/message';
const { useToken } = theme;

const lstIconMap =
    [
        { title: "Email", id: "email", icon: <i className="fa-solid fa-envelope"></i> },
        { title: "Facebook", id: "facebook", icon: <i className="fa-brands fa-facebook"></i> },
        { title: "Instagram", id: "instagram", icon: <i className="fa-brands fa-instagram"></i> },
        { title: "Tiktok", id: "tiktok", icon: <i className="fa-brands fa-tiktok"></i> },
        { title: "Youtube", id: "youtube", icon: <i className="fa-brands fa-youtube"></i> },
        { title: "Spotify", id: "spotify", icon: <i className="fa-brands fa-spotify"></i> },
        { title: "Twitter", id: "twitter", icon: <i className="fa-brands fa-x-twitter"></i> },

    ]

const SocicalLinks = ({ value, onChange }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [socicals, setSocicals] = useState(toArray(value));

    const message = useMessage();
    useEffect(() => {

        var maplinks = socicals.map(x => ({ id: x.id, url: x.url }));
        var valueMap = toArray(value).map(x => ({ id: x.id, url: x.url }));
        var equa = _.isEqual(valueMap, maplinks);
        if (!equa) {
            setSocicals(maplinks)
        }
    }, [value])

    const [lstSort, setLstSort] = useState([]);
    useEffect(() => {
        setLstSort([...socicals])

    }, [socicals])


    // useEffect(() => {
    //     var lst = [];
    //     for (let index = 0; index < socicals.length; index++) {
    //         var item = socicals[index];
    //         var icon = lstIconMap.find(x => x.id == item.id);
    //         lst.push({ ...icon, url: item.url })
    //     }
    //     setLstDisplay(lst);

    // }, [socicals])


    // useEffect(() => {
    //     var maplinks = socicals.map(x => ({ id: x.id, url: x.url }));
    //     var valueMap = toArray(value).map(x => ({ id: x.id, url: x.url }));
    //     var equa = _.isEqual(valueMap, maplinks);
    //     if (!equa) {
    //         console.log("onchange ", maplinks)
    //         onChange(maplinks);

    //     }
    // }, [socicals])






    const onAdd = (link) => {
        var newLinks = [...socicals];
        newLinks.push(link);
        setSocicals(newLinks);
        linksChange(newLinks);
    }
    const linksChange = (links) => {
        var maplinks = links.map(x => ({ id: x.id, url: x.url }));
        var valueMap = toArray(value).map(x => ({ id: x.id, url: x.url }));
        var equa = _.isEqual(valueMap, maplinks);
        if (!equa) {
            console.log("onchange ", maplinks)
            onChange(maplinks);

        }
    }


    const onRemove = async (link) => {
        var confirm = await message.showConfirm();
        if (!confirm) {
            return;
        }
        var newLinks = [...socicals];
        newLinks = newLinks.filter(x => x.id != link.id);
        setSocicals(newLinks);
        linksChange(newLinks);
    }

    const sortChange = (links) => {
        setSocicals(links);
        linksChange(links);
    }



    return (
        <div>
            <Space direction='vertical' style={{ width: "100%" }}>

                <div>
                    <ReactSortable list={socicals} setList={sortChange} swapThreshold={1}
                        animation={0}
                        handle='.handle'
                        group="socicallink"
                    // handle="abc"
                    >
                        {socicals.map(link => {
                            return (
                                <Link key={`link_socical_${link.id}`} link={link} onRemove={onRemove}> </Link>
                            )
                        })}
                    </ReactSortable>

                </div>
                <div>
                    <Flex justify="flex-end">
                        <Button onClick={e => setShowAddModal(true)} type='primary'>Add Socical</Button>
                    </Flex>

                </div>
            </Space>
            <ModalAdd open={showAddModal} afterColse={e => setShowAddModal(false)} onAdd={onAdd} notShowList={socicals.map(x => x.id)}></ModalAdd>
        </div>
    )

}


const ModalAdd = memo(({ open, afterColse, onAdd, notShowList }) => {

    const [modalOpen, setModalOpen] = useState(open);
    const [addLink, setAddLink] = useState(null);

    useEffect(() => {
        setModalOpen(open);
        setAddLink(null);
        socicalForm.setFieldsValue({ url: "" })
    }, [open])

    useEffect(() => {
        var lst = lstIconMap.filter(x => {
            return !(notShowList ?? []).includes(x.id);
        })
        setSocicals(lst);

    }, [notShowList])

    const close = () => {
        setModalOpen(false);
    }
    const [socicals, setSocicals] = useState([]);

    const onAddClick = (link) => {
        setAddLink(link);
    }
    const [socicalForm] = Form.useForm();
    const onOk = () => {
        var data = socicalForm.getFieldsValue(true);
        onAdd({ id: addLink.id, url: data.url })
        close();
    }
    return (
        <Modal title={addLink ? "Add " + addLink.title : "Add Socical"}
            open={modalOpen} afterClose={afterColse} onCancel={close} onOk={onOk}
            footer={(_, { OkBtn, CancelBtn }) => (
                <>

                    <CancelBtn />
                    {addLink &&
                        <OkBtn />
                    }

                </>
            )}
        >
            {addLink &&
                <>
                    <Form
                        form={socicalForm}
                        name="control-socicalform"
                        layout='vertical'
                    >
                        <FormItem name={"url"} label="">
                            <Input></Input>
                        </FormItem>

                    </Form>
                </>
            }

            {!addLink &&
                <>
                    {socicals.map(link => {
                        return (
                            <AddLink key={`link_socical_add_${link.id}`} link={link} onAdd={onAddClick}> </AddLink>
                        )
                    })}


                </>
            }


        </Modal>
    )
})

const Link = memo(({ link, onRemove }) => {
    const { token } = useToken();
    const [mapIcon, setMapIcon] = useState({});

    useEffect(() => {

        var icon = lstIconMap.find(x => x.id == link.id);
        setMapIcon({ ...icon })
    }, [link])

    return (
        <div style={{ display: "flex", flexDirection: "row", borderWidth: 1, borderColor: token.colorBorder, padding: token.paddingXS, borderStyle: "solid", marginBottom: 8, alignItems: "center" }}>

            <div >
                <span className='handle' style={{ cursor: "pointer" }}>
                    <Button type='link'>
                        <DragOutlined />
                    </Button>


                </span>
            </div>
            <div style={{ marginRight: 16 }}>
                {mapIcon.icon}

            </div>
            <div style={{ flexGrow: 1 }}>
                {mapIcon.title}
            </div>
            <div>
                <Space>

                    <Button type='link' onClick={e => onRemove(link)}>Delete</Button>
                </Space>

            </div>

        </div>
    )
})

const AddLink = memo(({ link, onAdd }) => {
    const { token } = useToken();

    return (
        <div style={{ display: "flex", flexDirection: "row", borderWidth: 1, borderColor: token.colorBorder, padding: token.paddingXS, borderStyle: "solid", marginBottom: 8, height: "100%", alignItems: "center" }}>
            <div style={{ marginRight: 16 }}>
                {link.icon}
            </div>
            <div style={{ flexGrow: 1 }}>
                {link.title}
            </div>
            <div>
                <Button type='link' onClick={e => onAdd(link)} >Add</Button>
            </div>
        </div>
    )
})

export default memo(SocicalLinks);