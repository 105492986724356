import { memo, useEffect, useState } from "react"
import { Button, Space, Table, Tag, Tabs, Input, Pagination, Image, Select, Upload } from 'antd';
import api from "api"
import { useNavigate, useParams } from "react-router-dom";
import { SelectButton, ImageSelect } from "components"
import { useMessage } from "hooks/message"
import { FullscreenOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import ImgCrop from 'antd-img-crop';
const { Search } = Input;
const Images = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const message = useMessage();
    var { type } = useParams();
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        var { data } = await api.get("/api/master/images/list");
        setData(data.data);
    }
    const goToEdit = (id) => {
        navigate(`/theme/${id}`)
    }

    const items = [
        {
            key: 'frompexels',
            label: 'Pexels',
            children: <Pexels type={type} />,
        },
        {
            key: 'from-upload',
            label: 'Upload',
            children: <UploadTab type={type}></UploadTab>,
        },
        {
            key: 'from-url',
            label: 'Url',
            children: <FromUrl type={type}></FromUrl>,
        },


    ];

    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}

const Pexels = memo(({ type }) => {
    const message = useMessage();

    const pageSize = 10;

    const [images, setImages] = useState([])
    const [page, setPage] = useState(1);
    const [uploading, setUploading] = useState(false);
    const [displayList, setDisplayList] = useState([])
    const [selectedIDs, setSelectedIDs] = useState([]);

    const [imageType, setImageType] = useState("background");

    useEffect(() => {

        var lst = [];
        var j = 0;
        for (let index = (page - 1) * pageSize; index < images.length; index++) {
            if (j >= pageSize) {
                break;
            }
            j++;

            lst.push(images[index])
        }
        setDisplayList(lst);

    }, [page, images])
    const onSearch = (text) => {
        console.log("search", text)
        searchImage(text);
    }

    const searchImage = async (text) => {
        setSelectedIDs([]);
        setImages([]);
        setPage(1);
        var { data } = await api.get("api/master/images/pexels", { params: { q: text } });
        setImages(data.data.photos);

    }
    const pageChange = (currentPage) => {
        console.log("onchane", currentPage);
        setPage(currentPage);
    }
    const imageClick = (id) => {
        var newSelect = [...selectedIDs];
        var i = newSelect.indexOf(id);
        if (i >= 0) {
            newSelect.splice(i, 1);
        } else {
            newSelect.push(id);
        }
        setSelectedIDs(newSelect);
    }
    const upload = async () => {
        setUploading(true);
        for (let index = 0; index < selectedIDs.length; index++) {

            var id = selectedIDs[index];
            var item = images.find(x => x.id == id);
            var postData = {
                image_id: item.id,
                image_name: item.alt,
                download_from: "pexels.com",
                url: item.src.original,
                file_type: type
            }
            await upload1Image(postData);
        }
        setSelectedIDs([]);
        setUploading(false);
        message.showSuccess("Upload xong");




    }

    const upload1Image = async (data) => {
        await api.post("api/master/images/uploadfromurl", data);
    }
    return (
        <div>
            <Space direction="vertical">


                <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
                <div>
                    <Pagination current={page} onChange={pageChange} total={images.length} pageSize={pageSize} />
                </div>
                <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>


                    {displayList.map(image => {
                        return (
                            <ImageSelect selected={selectedIDs.includes(image.id)} onClick={e => imageClick(image.id)} src={image.src.original}></ImageSelect>
                        )
                    })}
                </div>
                <div>
                    <div>Đã lựa chọn {selectedIDs.length} hình</div>
                    <div>
                        <Button type="primary" onClick={upload} loading={uploading}>Ok</Button>
                    </div>
                </div>
            </Space>
        </div>
    )
})



const UploadTab = memo(({ type, crop }) => {


    const [loading, setLoading] = useState(false);


    const uploadFile = async (file) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_type", type);
        var res = await api.post("/api/master/images/upload", formData);
        setLoading(false);


    }


    const beforeUpload = async (file) => {
        await uploadFile(file);
        return false;
    }

    const uploadButton = <Upload beforeUpload={beforeUpload} showUploadList={false}>
        <Button loading={loading} icon={<UploadOutlined />}>{loading ? "Uploading" : "Click to Upload"}</Button>
    </Upload>


    return (
        <div>
            <div>
                {crop ?
                    <ImgCrop {...crop}>
                        {uploadButton}
                    </ImgCrop>
                    :
                    <>
                        {uploadButton}
                    </>


                }



            </div>

        </div>
    )
})



const FromUrl = memo(({ type }) => {
    const message = useMessage();
    const pageSize = 10;
    const [images, setImages] = useState([])
    const [page, setPage] = useState(1);
    const [uploading, setUploading] = useState(false);
    const [displayList, setDisplayList] = useState([])
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        try {
            var array = JSON.parse(inputText).map(x => ({ id: x, src: x }));
            setImages(array);
        } catch (error) {
            setImages([]);
        }
    }, [inputText])


    useEffect(() => {

        var lst = [];
        var j = 0;
        for (let index = (page - 1) * pageSize; index < images.length; index++) {
            if (j >= pageSize) {
                break;
            }
            j++;

            lst.push(images[index])
        }
        setDisplayList(lst);

    }, [page, images])

    const onSearch = (text) => {
        console.log("search", text)
        searchImage(text);
    }

    const searchImage = async (text) => {
        setSelectedIDs([]);
        setImages([]);
        setPage(1);
        var { data } = await api.get("api/master/images/pexels", { params: { q: text } });
        setImages(data.data.photos);

    }
    const pageChange = (currentPage) => {
        console.log("onchane", currentPage);
        setPage(currentPage);
    }
    const imageClick = (id) => {
        var newSelect = [...selectedIDs];
        var i = newSelect.indexOf(id);
        if (i >= 0) {
            newSelect.splice(i, 1);
        } else {
            newSelect.push(id);
        }
        setSelectedIDs(newSelect);
    }
    const upload = async () => {
        setUploading(true);
        for (let index = 0; index < selectedIDs.length; index++) {

            var id = selectedIDs[index];
            var item = images.find(x => x.id == id);
            var postData = {
                image_id: item.id,

                download_from: item.id,
                url: item.src,
                file_type: type
            }
            await upload1Image(postData);
        }
        setSelectedIDs([]);
        setUploading(false);
        message.showSuccess("Upload xong");




    }

    const upload1Image = async (data) => {
        await api.post("api/master/images/uploadfromurl", data);
    }
    return (
        <div>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Input.TextArea value={inputText} onChange={e => setInputText(e.target.value)} ></Input.TextArea>
                <div>
                    <Pagination current={page} onChange={pageChange} total={images.length} pageSize={pageSize} />
                </div>
                <div style={{ flexDirection: "row", flexWrap: "wrap", display: "flex" }}>


                    {displayList.map(image => {
                        return (
                            <ImageSelect selected={selectedIDs.includes(image.id)} onClick={e => imageClick(image.id)} src={image.src}></ImageSelect>
                        )
                    })}
                </div>
                <div>
                    <a href="https://www.animatedimages.org/"> https://www.animatedimages.org/</a>
                    <p>JSON.stringify(Array.from(document.querySelectorAll("img[src*='.gif']")).map(x=>x.src))</p>
                </div>
                <div>
                    <div>Đã lựa chọn {selectedIDs.length} hình</div>
                    <div>
                        <Button type="primary" onClick={upload} loading={uploading}>Ok</Button>
                    </div>
                </div>
            </Space>
        </div>
    )
})

export default memo(Images)
