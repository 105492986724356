
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
function toArray(value) {
    if (!value)
        return [];
    if (!_.isArray(value)) {
        return [];
    }
    return value.map(x => x);
}
function newID() {
   return uuidv4();
}



export { toArray,newID }