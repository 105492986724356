import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox, Row, Col, Card, Space, Divider, Flex } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { AuthenticationProvider, useAuthentication } from "hooks/authentication"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google'
import api from "api"
import GoogleButton from 'react-google-button'

const LoginPage = () => {
    const { login } = useAuthentication();
    const [loading, setLoading] = useState(false);
    const onFinish = async values => {
        setLoading(true);
        var { data } = await api.post("api/login", values);
        if (data.code == 1) {
            var { token, user } = data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            login(user);
            setLoading(false);
        }

    };

    useEffect(() => {
        form.setFieldsValue({ email: "vuvantoanctk32@gmail.com", password: "123456" })
    }, [])

    const loginWithGoogle = async (token) => {


        var { data } = await api.post("api/login/google", { token: token });
        if (data.code == 1) {
            var { token, user } = data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            login(user);
            setLoading(false);
        }

    }

    const loginBtnClick2 = () => {

    }
    const loginBtnClick = useGoogleLogin({
        onSuccess: tokenResponse => loginWithGoogle(tokenResponse.access_token)
    });


    const [form] = Form.useForm();

    return (
        <Row justify="center" align="middle" style={{height: "100%"}}>

            <Col xs={20} sm={16} md={12} lg={16} xl={8} style={{transform: "translateY(-20%)"}}>

                <Card style={{ width: "100%", marginTop: 50 }}>
                    <div style={{ textAlign: "center", paddingBottom: 25, fontSize: 24, fontWeight: "bold", color: '#005AC1' }}>Login</div>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <a className="login-form-forgot" href="">
                                Forgot password
                            </a>
                        </Form.Item>

                        <Form.Item>
                            <Space direction='vertical' style={{ width: "100%" }}>
                                <Button loading={loading} type="primary" htmlType="submit" block className="login-form-button">
                                    Login
                                </Button>
                                <div style={{ alignItems: "flex-end", display: "flex", justifyContent: "flex-end" }}>
                                    <Space>
                                        Or <a href="/signup">register now!</a>
                                    </Space>
                                </div>
                            </Space>
                        </Form.Item>
                        <Divider>Or</Divider>
                        <Form.Item>
                            <Space direction='vertical' style={{ width: "100%" }}>

                                <Flex align='center' justify='center'>

                                    <GoogleButton
                                        type="light" // can be light or dark
                                        onClick={loginBtnClick}
                                    />

                                </Flex>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row >
    );
};

const LoginPage2 = () => {
    return (
        <GoogleOAuthProvider clientId="402471398378-9lvdclerachqsog4nl88etvadg2n4e3s.apps.googleusercontent.com">
            <LoginPage></LoginPage>
        </GoogleOAuthProvider>
    )
}


export default memo(LoginPage2)

